export enum ActStatus {
  REGISTERED = 'REGISTERED',
  DEPOSITING = 'DEPOSITING',
  FILLING = 'FILLING',
  DEPOSITED = 'DEPOSITED',
  WITHDRAWN = 'WITHDRAWN',
  UTILIZED = 'UTILIZED',
  DELETED = 'DELETED',
}

export enum StorageStatus {
  INACTIVE = 'INACTIVE',
  DEPOSITING = 'DEPOSITING',
  FILLING = 'FILLING',
}

export enum PaymentType {
  CARD = 'CARD',
  CASH = 'CASH',
}

export interface IArea {
  id: string,
  name: string,
  areas: IArea[]
}

interface IWithdrawAct {
  id: string,
  storageEmployeeName: string,
  name: string,
  phoneNumber: string
} 

interface IUtilizeAct {
  id: string,
  employeeName: string,
  secondEmployeeName: string,
  chairmanName: string
} 

export interface IStoragePrice {
  id: string,
  initPrice: number,
  continuePrice: number,
  longContinuePrice: number,
}

export interface IAct {
  id: string,
  number: string,
  actDate: string,
  createAt: string,
  area: IArea,
  description: string | null,
  storageDescription: string | null,
  isInspected: boolean,
  shelveNumber: string | null,
  tagNumber: string | null,
  itemTypeId: string | null,
  status: ActStatus,
  photoUrls: {
    [key: string]: string
  },
  employeeName: string,
  weight: number,
  withdrawAct: IWithdrawAct | null,
  utilizeAct: IUtilizeAct | null,
  storageTime: number,
}

export interface ISubCategory {
  id: string,
  name: string,
}

export interface ICategory {
  name: string,
  types: ISubCategory[],
}

export interface IPayment {
  type: PaymentType | string,
}
