import {rootApi} from '..';
import { objectToParamString } from '../../../utils/params';
import {
  ActGetParams, 
  DeclineActsPostParams, 
  DepositActsPostParams,
  FillActPostParams,
  ReceiveActsPostParams,
  StartWithdrawActPostParams,
  UtilizeActPostParams,
  WithdrawActPostParams
} from './types/request';
import { 
  GetActByIdResponse, 
  GetActResponse, 
  GetCategoriesResponse, 
  GetStorageAreasResponse, 
  GetStorageResponse, 
  GetStorageStateResponse, 
  PostActResponse,
  PostActsResponse,
  PostStartWithdrawResponse
} from './types/response';

const authApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getStorageState: build.query<GetStorageStateResponse, void>({
      query: () => ({
        url: '/storage/state',
        method: 'GET',
      }),
    }),
    getStorageAreas: build.query<GetStorageAreasResponse, void>({
      query: () => ({
        url: '/storage/areas',
        method: 'GET',
      }),
    }),
    getActs: build.query<GetActResponse, ActGetParams>({
      query: (params) => {
        const p = {...params, limit: 10};
        return ({
          url: `/storage/acts${
            typeof(p) == 'object' 
            ? objectToParamString(p)
            : p}`,
        })
      },
      providesTags: ["Act"]
    }),
    getActById: build.query<GetActByIdResponse, string>({
      query: (actId) => ({
        url: `/storage/acts/${actId}`,
        method: 'GET',
      }),
    }),
    getCategories: build.query<GetCategoriesResponse, void>({
      query: () => ({
        url: '/storage/categories',
        method: 'GET',
      }),
    }),
    getStorage: build.query<GetStorageResponse, void>({
      query: () => ({
        url: '/storage/',
        method: 'GET',
      }),
    }),
    depositActs: build.mutation<void, DepositActsPostParams>({
      query: (body) => ({
        url: '/storage/depositActs',
        method: 'POST',
        data: body
      }),
    }),
    receiveAct: build.mutation<PostActsResponse, ReceiveActsPostParams>({
      query: (body) => ({
        url: `/storage/acts/receive`,
        method: 'POST',
        data: body
      })
    }),
    declineAct: build.mutation<PostActsResponse, DeclineActsPostParams>({
      query: (body) => ({
        url: `/storage/acts/decline`,
        method: 'POST',
        data: body
      })
    }),
    startWithdrawAct: build.mutation<PostStartWithdrawResponse, StartWithdrawActPostParams>({
      query: (body) => ({
        url: `/storage/startWithdrawAct`,
        method: 'POST',
        data: body
      })
    }),
    withdrawAct: build.mutation<PostActResponse, WithdrawActPostParams>({
      query: (body) => ({
        url: `/storage/withdrawAct`,
        method: 'POST',
        data: body
      })
    }),
    utilizeAct: build.mutation<PostActResponse, UtilizeActPostParams>({
      query: (body) => ({
        url: `/storage/utilizeAct`,
        method: 'POST',
        data: body
      })
    }),
    fillAct: build.mutation<PostActResponse, FillActPostParams>({
      query: (body) => ({
        url: `/storage/fillAct`,
        method: 'POST',
        data: body
      })
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetStorageQuery,
  useLazyGetStorageAreasQuery,
  useLazyGetStorageStateQuery,
  useLazyGetActsQuery,
  useLazyGetActByIdQuery,
  useLazyGetCategoriesQuery,
  useDepositActsMutation,
  useReceiveActMutation,
  useDeclineActMutation,
  useWithdrawActMutation,
  useUtilizeActMutation,
  useFillActMutation,
  useStartWithdrawActMutation
} = authApi;
