import React from 'react';
import block from 'bem-cn-lite';
import {Button, Checkbox, Icon, Label, Text} from '@gravity-ui/uikit';
import moment from 'moment';
import { Gallery, Item } from 'react-photoswipe-gallery';


import {Xmark} from '@gravity-ui/icons';
import {Check} from '@gravity-ui/icons';
import {CircleFill} from '@gravity-ui/icons';

import './ActCard.scss';
import {ActStatus, IAct, IArea} from '../../store/api/storage/types/models';
import { getImageMeta, IImageSize, uiElements } from './lib';

const b = block('act-card');

const MAX_CHAR_LENGTH = 129;

interface ActCardProps {
  act: IAct;
  focused: boolean;
  selected: boolean;
  onClick: () => void;
  onSelect: (actId: string) => void;
  onReceiveAct: () => void;
  onDeclineAct: () => void;
}

export const ActCard: React.FC<ActCardProps> = ({
  act, 
  selected, 
  focused, 
  onClick, 
  onSelect,
  onReceiveAct,
  onDeclineAct
}) => {
  const [imagesSize, setImagesSize] = React.useState<IImageSize | null>(null)
  const countPhoto = Object.keys(act.photoUrls).length;
  const [showDesc, setShowDesc] = React.useState(false);
  const toggleDesc = (e: any) => {
    e.stopPropagation();
    setShowDesc(!showDesc);
  };

  const handleOnChangeCheckbox = (e: any) => {
    e.stopPropagation();
    onSelect(act.id);
  };

  const getFullAreaPath = (area: IArea): string[] => {
    let fullPath: string[] = [];
    fullPath.push(area.name);
    for (let i = 0; i < area.areas.length; i++) {
      fullPath = fullPath.concat(getFullAreaPath(area.areas[i]));
    }
    return fullPath;
  }

  const additionalComponents = () => {
    switch (act.status) {
      case ActStatus.DEPOSITING:
        return (
          <>
            <Button
              onClick={onDeclineAct}
              view="outlined-danger"
              style={{
                marginRight: 8,
              }}
            >
              Отклонить
              <Icon data={Xmark} size={18} />
            </Button>
            <Button
              onClick={onReceiveAct} 
              view="outlined-success"
            >
              Принять
              <Icon data={Check} size={18} />
            </Button>
          </>
        );
      case ActStatus.FILLING:
      case ActStatus.DEPOSITED:
        return (
          <Label
            theme={act.status === ActStatus.DEPOSITED ? 'success' : 'warning'}
            icon={<Icon size={8} data={CircleFill} />}
            className={b('saved-status-label')}
          >
            {act.status === ActStatus.DEPOSITED ? 'Заполнен' : 'К заполнению'}
          </Label>
        );
      case ActStatus.WITHDRAWN:
        return (
          <Label
            theme="success"
            icon={<Icon size={8} data={CircleFill} />}
            className={b('saved-status-label')}
          >
            Выдан
          </Label>
        );
      case ActStatus.UTILIZED:
        return (
          <Label
            theme="danger"
            icon={<Icon size={8} data={CircleFill} />}
            className={b('saved-status-label')}
          >
            Утилизирован
          </Label>
        );
      default:
        return <></>;
    }
  };

  const initImagesSize = async () => {
    Object.keys(act.photoUrls).forEach(async (urlKey) =>  {
      const img = await getImageMeta(act.photoUrls[urlKey]);
      setImagesSize(old => old 
        //@ts-ignore
        ? {...old, [urlKey]: { width: img.naturalWidth, heigth: img.naturalHeight }} 
        //@ts-ignore
        : {[urlKey]: { width: img.naturalWidth, heigth: img.naturalHeight }})
    })
  }

  React.useEffect(() => {
    initImagesSize()
  }, [])

  return (
    <div className={b({selected: focused})} onClick={onClick}>
      <div className={b('card-btns')}>{additionalComponents()}</div>

      <div className={b('img-wrapper')}>
        {act.status === ActStatus.DEPOSITING ? (
          <Checkbox
            checked={selected}
            onChange={handleOnChangeCheckbox}
            size="l"
            className="checkbox"
          />
        ) : null}

        <Gallery 
          uiElements={uiElements}
          withDownloadButton
          options={{
            hideAnimationDuration: 0,
          }}
        >
          {imagesSize && Object.keys(imagesSize).length === countPhoto
            ? <>
                {Object.keys(act.photoUrls).map(imgKey => 
                  <Item
                    key={act.photoUrls[imgKey]}
                    original={act.photoUrls[imgKey]}
                    thumbnail={act.photoUrls[imgKey]}
                    width={imagesSize[imgKey].width * 5}
                    height={imagesSize[imgKey].heigth * 5}
                  >
                    {({ ref, open }) => (
                      <img
                        ref={ref}
                        onClick={open} 
                        alt="act"
                        src={act.photoUrls[Object.keys(act.photoUrls)[0]]} 
                      />
                    )}
                  </Item>
                )}
              </>
            : null
          }
        </Gallery>
        <span className="count">{countPhoto}</span>
      </div>
      <div className={b('act-info')}>
        <span className={b('title')}>Акт №{act.number}</span>

        <div className={b('row-info')}>
          <span className="name">ФИО сотрудника САБ</span>
          <span className="value">{act.employeeName}</span>
        </div>

        <div className={b('row-info')}>
          <span className="name">Дата и время</span>
          <span className="value">{moment(act.actDate).format('DD.MM.YYYY, в HH:mm')}</span>
        </div>

        <div className={b('row-info')}>
          <span className="name">Место обнаружения</span>
          <span className="value">{getFullAreaPath(act.area).join(', ')}</span>
        </div>

        <div className={b('row-info')}>
          <span className="name">Акт осмотра</span>
          <span className="value">
            {act.isInspected
              ? <>
                  Составлен {<Icon size={16} data={Check} />}
                </>
              : <>
                  Отсутствует {<Icon size={16} data={Xmark} />}
                </>
            }
          </span>
        </div>

        <div className={b('row-info')}>
          <span className="name">Описание САБ</span>
          <Text className="value">
            {act.description
              ? act.description.length <= MAX_CHAR_LENGTH
                ? act.description
                : showDesc
                  ? act.description
                  : act.description.slice(0, MAX_CHAR_LENGTH)
              : null
            }
            {act.description && act.description.length > MAX_CHAR_LENGTH
              ? <Text color="info" onClick={toggleDesc} style={{marginLeft: 4, cursor: 'pointer'}}>
                  {showDesc ? 'Скрыть' : 'Показать все'}
                </Text>
              : null
            }
            
          </Text>
        </div>
      </div>
    </div>
  );
};
