import React from 'react';
import block from 'bem-cn-lite';
import {dateTime, DateTime, settings} from '@gravity-ui/date-utils';
import './ActSidePanel.scss';
import {EmptySidePanel} from './ui';
import {EnterActDataForm} from './ui/FormsByStatus';
import {ActStatus, IAct} from '../../store/api/storage/types/models';
import { FillActPostParams } from '../../store/api/storage/types/request';
import { EditDatePopup } from './ui/EditDatePopup';

settings.loadLocale('ru').then(() => {
  settings.setLocale('ru');
  settings.getLocale();
});

const b = block('act-side-panel');

interface ActSidePanelProps {
  act: IAct | null;
  onEnterDataClick: (params: FillActPostParams) => void;
  onGiveOutFindClick: () => void;
  onRecycleFindClick: () => void;
  onSelectFirstActClick: () => void;
}

export const ActSidePanel: React.FC<ActSidePanelProps> = ({
  act,
  onEnterDataClick,
  onGiveOutFindClick,
  onRecycleFindClick,
  onSelectFirstActClick,
}) => {
  
  const [storageActDate, setStorageActDate] = React.useState<DateTime | null>(null);

  const handleOnDateEdit = (newDate: DateTime | null) => setStorageActDate(newDate);

  const sidePanelForms = () => {
    if (!act) return;

    switch (act.status) {
      case ActStatus.FILLING:
      case ActStatus.DEPOSITED:
      case ActStatus.WITHDRAWN:
      case ActStatus.UTILIZED:
        return (
          <>
            <EnterActDataForm
              act={act}
              storageActDate={storageActDate}
              onEnterDataClick={onEnterDataClick}
              onGiveOutFindClick={onGiveOutFindClick}
              onRecycleFindClick={onRecycleFindClick}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  React.useEffect(() => {
    setStorageActDate(null);
  }, [act])

  return (
    <div className={b()}>
      {act ? (
        <>
          <div className={b('header')}>
            <span className={b('header-title')}>Акт №{act.number}</span>
            <div className={b('datetime')}>
              <span className={'text'}>
                {storageActDate 
                  ? storageActDate.format('DD.MM.YYYY, HH:mm')
                  : dateTime({ input: act.actDate }).format('DD.MM.YYYY, HH:mm')
                }
                {act.status === ActStatus.FILLING
                  ? <EditDatePopup 
                      actDate={storageActDate || dateTime({ input: act.actDate })}
                      onDateEdit={handleOnDateEdit}
                    />
                  : null
                }
                
              </span>
            </div>
          </div>
          <div className={b('form')}>
            {sidePanelForms()}
          </div>
        </>
      ) : (
        <EmptySidePanel onSelectFirstActClick={onSelectFirstActClick} />
      )}
    </div>
  );
};
