import React from 'react';
import block from 'bem-cn-lite';
import {Button, Icon, Select, TextInput, useToaster} from '@gravity-ui/uikit';

import './GiveOutFormModal.scss';
import {CircleXmarkFill, Xmark} from '@gravity-ui/icons';
import {FormModal} from '../../../../components/modal';
import {InputLabel} from '../../../../components/input-label';
import {clearFormattedPhoneNumber, formatPhoneNumber} from '../../../../utils/phonenumber';
import {PrimaryButton} from '../../../../components/button';
import { WithdrawActPostParams } from '../../../../store/api/storage/types/request';
import { IAct, PaymentType } from '../../../../store/api/storage/types/models';
import { isDigitOrEmpty } from '../../../../utils/validation';
import { useLazyGetStorageQuery, useStartWithdrawActMutation } from '../../../../store/api/storage';

const b = block('give-out-form-modal');


interface GiveOutFormModalProps {
  open: boolean;
  act: IAct | null;
  onClose: () => void;
  onSubmit: (params: WithdrawActPostParams) => void;
}

export const GiveOutFormModal: React.FC<GiveOutFormModalProps> = ({
  open,
  act,
  onClose, 
  onSubmit
}) => {
  const {add} = useToaster();

  const [isStoragePrice, setIsStoragePrice] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [clearPhone, setClearPhone] = React.useState("");
  const [paymentType, setPaymentType] = React.useState<string[]>([]);

  const [getStorage, {
      data: getStorageResponse,
      ...getStorageRequestInfo
    }] = useLazyGetStorageQuery();

    const [startWithdraw, {
      data: startWithdrawResponse,
      ...startWithdrawRequestInfo
    }] = useStartWithdrawActMutation();

  const totalPrice = React.useMemo(() => {
    if (!startWithdrawResponse || !getStorageResponse) return 0;

    if (startWithdrawResponse.result.storageTime < 3) {
      return getStorageResponse.result.initPrice / 100;
    } else if (startWithdrawResponse.result.storageTime === 3) {
      return getStorageResponse.result.initPrice / 100 + getStorageResponse.result.continuePrice / 100;
    } else if (startWithdrawResponse.result.storageTime > 3) {
      return getStorageResponse.result.initPrice / 100 + getStorageResponse.result.continuePrice / 100 + (startWithdrawResponse.result.storageTime - 3) * getStorageResponse.result.longContinuePrice / 100;
    } else {
      return 0;
    }
  }, [getStorageResponse, startWithdrawResponse])

  const handleOnCloseBtnClick = () => {
    clearInput();
    onClose();
    setIsStoragePrice(false);
  }

  const handleOnChangeName = (e: any) => setName(e.target.value);

  const handleOnChangePaymentType = (newValue: string[]) => setPaymentType(newValue);

  const handleOnChangePhone = (e: any) => {
    const clearedPhoneNumber = clearFormattedPhoneNumber(e.target.value);

    if (isDigitOrEmpty(clearedPhoneNumber)) {
      const formattedPhoneNumber = formatPhoneNumber(clearedPhoneNumber);
      
      if (clearedPhoneNumber.length < 12) {
        setPhone(formattedPhoneNumber);
        setClearPhone(clearedPhoneNumber)
      }
    }
  };

  const clearInput = () => {
    setClearPhone("");
    setPhone("");
    setName("");
    setPaymentType([]);
  }

  const handleOnToStoragePriceBtnClick = () => {
    setIsStoragePrice(true);
  }

  const handleOnGiveOutBtnClick = () => {
    if (act && startWithdrawResponse) {
      onSubmit({
        actId: act.id,
        operationId: startWithdrawResponse.result.operationId,
        phoneNumber: '+' + clearPhone,
        name,
        payment: {
          type: paymentType[0],
        }
      });
      clearInput();
    }
  }

  const normalizeCountForm = (number: number, wordsArr: string[]) => {
    number = Math.abs(number);
    if (Number.isInteger(number)) {
      let options = [2, 0, 1, 1, 1, 2];
      return wordsArr[(number % 100 > 4 && number % 100 < 20) ? 2 : options[(number % 10 < 5) ? number % 10 : 5]];
    }
    return wordsArr[1];
  }

  React.useEffect(() => {
    if (open) {
      getStorage();
      if (act) startWithdraw({actId: act.id});
    } else {
      setIsStoragePrice(false);
    }
  }, [open, act])

  React.useEffect(() => {
    if ( 
      (getStorageRequestInfo.isError && getStorageRequestInfo.error) ||
      (startWithdrawRequestInfo.isError && startWithdrawRequestInfo.error)
    ) {

      // @ts-ignore
      const errorMessage = getStorageRequestInfo?.error?.error.message || startWithdrawRequestInfo?.error?.error.message || "";

      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Невозможно выполнить запрос`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        content: errorMessage,
      });
    }
  }, [
    getStorageRequestInfo.isError, getStorageRequestInfo.error,
    startWithdrawRequestInfo.isError, startWithdrawRequestInfo.error
  ]);

  React.useEffect(() => {
    if (
      clearPhone && clearPhone.length === 11 && 
      name && paymentType.at(0)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [clearPhone, name, paymentType])

  return (
    <FormModal
      width={400}
      open={open}
      onClose={handleOnCloseBtnClick}
      title={isStoragePrice ? "Расчетная стоимость" : "Выдача находки"}
      description={isStoragePrice 
        ? `Срок хранения: ${startWithdrawResponse?.result.storageTime || 1} ${normalizeCountForm(startWithdrawResponse?.result.storageTime || 1, ["день", "дня", "дней"])}` 
        : "Внесите данные для выдачи"}
      actions={
        <div className="action-btns">
          <Button
            view="flat"
            onClick={handleOnCloseBtnClick}
            style={{
              color: '#00000080',
            }}
          >
            Отмена
            <Icon data={Xmark} size={16} />
          </Button>
          {isStoragePrice
            ? <PrimaryButton
                onClick={handleOnGiveOutBtnClick}
                disabled={disabled}
              >
                Выдать
              </PrimaryButton>
            : <PrimaryButton
                onClick={handleOnToStoragePriceBtnClick}
                disabled={disabled}
              >
                Продолжить
              </PrimaryButton>
          }
        </div>
      }
    >
      <div className={b()}>
        {!isStoragePrice
          ? <>
              <InputLabel labelText="ФИО пассажира">
                <TextInput
                  value={name}
                  onChange={handleOnChangeName}
                  size="l"
                  placeholder="Иван Иванович Иванов"
                />
              </InputLabel>

              <InputLabel labelText="Телефон">
                <TextInput
                  value={phone}
                  onChange={handleOnChangePhone}
                  size="l"
                  placeholder="+7 (999) 000-00-00"
                />
              </InputLabel>

              <InputLabel labelText="Тип платежа">
                <Select 
                  width='max'
                  placeholder='Безналичный'
                  size="l" 
                  value={paymentType} 
                  onUpdate={handleOnChangePaymentType}
                >
                  <Select.Option value={PaymentType.CARD}>Безналичный</Select.Option>
                  <Select.Option value={PaymentType.CASH}>Наличный</Select.Option>
                </Select>
              </InputLabel>
            </>
          : <>
              <InputLabel labelText="Услуга за прием и оформление забытых вещей">
                <TextInput
                  readOnly
                  value={`${(getStorageResponse?.result.initPrice || 0)  / 100},00 ₽`}
                  size="l"
                />
              </InputLabel>
              <InputLabel labelText="Услуга хранения забытого багажа за 3-ий день">
                <TextInput
                  readOnly
                  disabled={Boolean(startWithdrawResponse && startWithdrawResponse.result.storageTime < 3)}
                  value={`${(getStorageResponse?.result.continuePrice || 0)  / 100},00 ₽`}
                  size="l"
                />
              </InputLabel>
              <InputLabel labelText="Услуга хранения забытого багажа более 3х дней">
                <TextInput
                  readOnly
                  disabled={Boolean(startWithdrawResponse && startWithdrawResponse.result.storageTime <= 3)}
                  value={`${(getStorageResponse?.result.longContinuePrice || 0)  / 100},00 ₽ ${startWithdrawResponse && startWithdrawResponse.result.storageTime > 3 ? `* ${startWithdrawResponse.result.storageTime - 3}` : ""}`}
                  size="l"
                />
              </InputLabel>
              <InputLabel labelText="ИТОГО:">
                <TextInput
                  readOnly
                  value={`${totalPrice},00 ₽`}
                  size="l"
                />
              </InputLabel>
            </>
        }
      </div>
    </FormModal>
  );
};
